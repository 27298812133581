/*--- SYNTAX HIGHLIGHTING ---------------------------------------------------*/

div.highlighter-rouge, figure.highlight {
    position: relative;
    margin-bottom: 1.5em;
    color: #d0d0d0;
    //@include font-rem(12);
    font-size: 12px;
    line-height: 1.5;
    border: 1px solid darken($body-color, 5);
    border-radius: 3px;
    background-color: #202020; //$body-color;

    .highlight {
        margin: 0;
        padding: 1em;
    }
}

figure.highlight {
    padding-left: 1em;
    padding-right: 1em;
}

.highlight table {
    font-size: 1em;
    border: 0;

    td {
        padding: 5px;
        border: 0;

        // line numbers
        &.gutter {
        padding-right: 1em;
        color: #ccc;
        }
    }

    pre {
        margin: 0;
    }
}

.highlight pre { 
    width: 100%; 
}

.highlight {	
	.lineno { 
        padding-right: 24px; 
        color: #8f908a;
    }
    
	.hll { background-color: #49483e }
    
    /* Error */
	.err {                     
        color: #960050; 
        background-color: #1e0010 
    }
    
    .c { color: #75715e }           /* Comment */
	.k { color: #66d9ef }          /* Keyword */
	.l { color: #ae81ff }          /* Literal */
	.n { color: #f8f8f2 }          /* Name */
	.o { color: #f92672 }          /* Operator */
	.p { color: #f8f8f2 }          /* Punctuation */
	.cm { color: #75715e }         /* Comment.Multiline */
	.cp { color: #75715e }         /* Comment.Preproc */
	.c1 { color: #75715e }         /* Comment.Single */
	.cs { color: #75715e }         /* Comment.Special */
	.ge { font-style: italic }     /* Generic.Emph */
	.gs { font-weight: bold }      /* Generic.Strong */
	.kc { color: #66d9ef }         /* Keyword.Constant */
	.kd { color: #66d9ef }         /* Keyword.Declaration */
	.kn { color: #f92672 }         /* Keyword.Namespace */
	.kp { color: #66d9ef }         /* Keyword.Pseudo */
	.kr { color: #66d9ef }         /* Keyword.Reserved */
	.kt { color: #66d9ef }         /* Keyword.Type */
	.ld { color: #e6db74 }         /* Literal.Date */
	.m { color: #ae81ff }          /* Literal.Number */
	.s { color: #e6db74 }          /* Literal.String */
	.na { color: #a6e22e }         /* Name.Attribute */
	.nb { color: #f8f8f2 }         /* Name.Builtin */
	.nc { color: #a6e22e }         /* Name.Class */
	.no { color: #66d9ef }         /* Name.Constant */
	.nd { color: #a6e22e }         /* Name.Decorator */
	.ni { color: #f8f8f2 }         /* Name.Entity */
	.ne { color: #a6e22e }         /* Name.Exception */
	.nf { color: #a6e22e }         /* Name.Function */
	.nl { color: #f8f8f2 }         /* Name.Label */
	.nn { color: #f8f8f2 }         /* Name.Namespace */
	.nx { color: #a6e22e }         /* Name.Other */
	.py { color: #f8f8f2 }         /* Name.Property */
	.nt { color: #f92672 }         /* Name.Tag */
	.nv { color: #f8f8f2 }         /* Name.Variable */
	.ow { color: #f92672 }         /* Operator.Word */
	.w { color: #f8f8f2 }          /* Text.Whitespace */
	.mf { color: #ae81ff }         /* Literal.Number.Float */
	.mh { color: #ae81ff }         /* Literal.Number.Hex */
	.mi { color: #ae81ff }         /* Literal.Number.Integer */
	.mo { color: #ae81ff }         /* Literal.Number.Oct */
	.sb { color: #e6db74 }         /* Literal.String.Backtick */
	.sc { color: #e6db74 }         /* Literal.String.Char */
	.sd { color: #e6db74 }         /* Literal.String.Doc */
	.s2 { color: #e6db74 }         /* Literal.String.Double */
	.se { color: #ae81ff }         /* Literal.String.Escape */
	.sh { color: #e6db74 }         /* Literal.String.Heredoc */
	.si { color: #e6db74 }         /* Literal.String.Interpol */
	.sx { color: #e6db74 }         /* Literal.String.Other */
	.sr { color: #e6db74 }         /* Literal.String.Regex */
	.s1 { color: #e6db74 }         /* Literal.String.Single */
	.ss { color: #e6db74 }         /* Literal.String.Symbol */
	.bp { color: #f8f8f2 }         /* Name.Builtin.Pseudo */
	.vc { color: #f8f8f2 }         /* Name.Variable.Class */
	.vg { color: #f8f8f2 }         /* Name.Variable.Global */
	.vi { color: #f8f8f2 }         /* Name.Variable.Instance */
	.il { color: #ae81ff }         /* Literal.Number.Integer.Long */
}