
/*--- LINKS -----------------------------------------------------------------*/

a {  
	text-decoration: none;
	color: $highlight-color; 

	&:visited { 
		color: lighten($highlight-color, 0); 
	}

	&:hover { 
		color: lighten($highlight-color, 0); 
	}

	&:focus { 
		outline: thin dotted; 
		color: darken($highlight-color, 0);
	}

	&:hover, 
	&:active { 
		outline: 0; 
	}
}

/*--- BLOCKQUOTE ------------------------------------------------------------*/

blockquote {
    border-left: 4px solid $border-color;
    font-size: 14px;
    font-family: $alt-font;
    margin-left: 1em;
    margin-right: 5em;
    padding-left: 15px;
    line-height: 22px;
}

/*--- BREADCRUMBS -----------------------------------------------------------*/

#breadcrumbs {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

/*--- CODE FORMAT -----------------------------------------------------------*/

tt, code, kbd, samp, pre {
	font-family: $code-font;
}

p > code,
a > code,
li > code,
figcaption > code,
td > code {
    margin: 0 2px;
	padding: 0 5px;
    font-size: 12px;
	line-height: 1.5;
    border: 1px solid lighten(#000, 90);
	background-color: lighten(#000, 95);
	border-radius: 3px;
}

pre {
    font-size: 12px;
	line-height: 1.5;
	overflow-x: auto;

	&::-webkit-scrollbar {
		height: 12px;
		background-color: #34362e;
		border-radius: 0 0 4px 4px;
	}

	&::-webkit-scrollbar-thumb:horizontal {
		background-color: #6a6d5d;
		border-radius: 4px;
	}
}

/*--- ABOUT SECTION (SOCIAL MEDIA) ------------------------------------------*/

.personal-social-media {
    word-spacing: 10px;
    
    img:hover {
        opacity: 0.5;
    }
}

/*--- DIVIDING LINE ---------------------------------------------------------*/

hr { 
    display: block; 
    margin: 1em 0 1.5em; 
    padding: 0;
    height: 1px; 
    border: 0; 
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #fff; 
}

/*--- FIGURES & IMAGES ------------------------------------------------------*/

figure {
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;

  img {
  	margin: 0 auto;
    margin-bottom: 0px;
    display: block;
  }
}

figcaption {
	text-align: center;
	font-size: .75em;
}

svg:not(:root) {
  overflow: hidden; 
}

/*--- PAGINATION ------------------------------------------------------------*/

.pagination {
    a{
        color: lighten($base-color,30);
    }
    
    a:hover {
        color: lighten($base-color,60);
    }
    
    //word-spacing: 10px;
}

/*--- EQUATIONS -------------------------------------------------------------*/

.has-jax {
    font: inherit; 
    font-size: 100; 
    background: inherit; 
    border: inherit;
    color: #006699;
}

/*--- NAVIGATION BAR --------------------------------------------------------*/

.navigation-bar {
    //word-spacing: 20px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
	    padding-left: 10px;
	    padding-right: 10px;
	    display: inline-block;
    }

    a{
    	text-transform: uppercase;
        //color: $highlight-color;
        //color: $base-color;
        color: lighten($base-color,30);
        font-weight: 300;
    }
    
    a:hover {
    	text-transform: uppercase;
        color: $highlight-color;
        //color: lighten($base-color,60);
        font-weight: 300;
    }
}

/*--- TAGS ------------------------------------------------------------------*/

.inline-tags {
    font-size: 14px;

    a {
        color: $highlight-color;
    }
    
    &:hover {
        color: lighten($highlight-color,0);
    }
}

/*--- GLOBAL TRANSITION -----------------------------------------------------*/

b, i, strong, em, blockquote, p, q, span, figure, img, h1, h2, header, input, a {
	transition: all 0.2s ease;
}

/*--- MAIN CONTENT ----------------------------------------------------------*/

.entry-content {
    font-size: 16px;
    line-height: 26px;
    a{
    	color: $highlight-color;
    }
}

.entry-title {
    font-weight: 300;
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.entry-subtitle {
	font-weight: 300;
	text-align: left;
}

li.entry-title{
    padding-bottom:10px;
}

.main-header-image {
    img {
        @media #{$medium} {
            margin-left: 0px;
			margin-right: 0px;
			max-width: $column-width;
		}

		@media #{$large} {
			max-width: $column-width;
		}
    }

    h1, a:link, a:visited, a:hover, a:active, div {
    	text-transform: uppercase;
        color: $highlight-color;
		//color: lighten($base-color, 60);
		letter-spacing: 5px;
	}
}

.post-date {
    font-size: 14px;
	text-transform: none;
    color: lighten($base-color,0);

	a {
		color: lighten($base-color,0);
	}
    
    a:hover{
        color: lighten($base-color,0);
    }
}

.post-byline {
    font-size: 14px;
	text-transform: none;
    color: lighten($base-color,0);

	a {
		color: lighten($highlight-color,0);
	}
    
    a:hover{
        color: lighten($highlight-color,0);
    }
}

header .entry-meta {
	display: none; // hide header meta on small screens

	@media #{$medium} {
		display: block;
	}
}

/*--- POST INDEX ------------------------------------------------------------*/

#post-index {
	#main {
		margin: 40px 2px 20px 2px;

    @media #{$small} {
      max-width: $column-width;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }

		@media #{$medium} {
			max-width: $column-width;
			margin-top: 50px;
			margin-left: auto;
			margin-right: auto;
		}

		@media #{$large} {
			max-width: $column-width;
			margin-top: 50px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	article {
		background-color: #fff;
		box-shadow: 0 0 0 0, 0 6px 12px rgba($base-color,0.2);
		border-radius: 3px;
		margin-bottom: 20px;
		padding: 0px 0px 50px 0px;
		overflow: hidden;

	@media #{$toosmall} {
      margin-bottom: 25px;
      padding: 25px 10px;

      .featured-image {
            display:block;
            margin:-50px -80px;
            padding: 0px 0px 50px 0px;
          }
    }

		@media #{$small} {
      margin-bottom: 25px;
      padding: 25px 50px;

      .featured-image {
            display:block;
            margin:-50px -80px;
            padding: 0px 0px 50px 0px;
          }
    }

		@media #{$medium} {
      margin-bottom: 25px;
      padding: 25px 50px;

      .featured-image {
            display:block;
            margin:-50px -80px;
            padding: 0px 0px 50px 0px;
          }
    }

		@media #{$large} {
			margin-bottom: 25px;
			padding: 25px 50px;

			.featured-image {
				    display:block;
	        	margin:-50px -80px;
	        	padding: 0px 0px 50px 0px;
	        }
		}
       
        a { 

            img:hover {
                filter: brightness(110%);
            }
        }

        
	}
}

/*--- FOOTER ----------------------------------------------------------------*/

.footer-wrapper {
	margin: 2em auto;
	text-align: center;
	color: lighten($text-color,20);
    font-size: 14px;

    a:link{
        color: lighten($text-color,20);
    }
    
	a:hover {
		color: lighten($text-color,60);
	}
}

/*--- BROWSER UPGRADE -------------------------------------------------------*/

.upgrade {
	padding: 10px;
	text-align: center;
}

/*--- BODY ------------------------------------------------------------------*/

body {
    font-family: $base-font;
    color: $text-color;
    font-weight: 300;
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: $body-color;
    margin-top: 0px + $doc-line-height;
    font-size: 0px + $doc-font-size;
    text-align: justify;
}

/*--- HEADINGS --------------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;

    a {  
        text-decoration: none;
        color: lighten($link-color, 0); 

        &:visited { 
            color: lighten($link-color, 0); 
        }

        &:hover { 
            color: $highlight-color; 
        }

        &:focus { 
            outline: thin dotted; 
            color: darken($link-color, 20);
        }

        &:hover, 
        &:active { 
            outline: 0; 
        }
    }
}


h1 {
	@media #{$medium} {
        font-size: 32px;
	}
}

/*--- CARD GRID ----------------------------------------------------------------*/
.card-grid{
  display: flex;
  align-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  /*background: #F1F1F2;*/
  display: flex;
  align-items: center;

  border: 1px solid lighten($base-color, 30);
  // box-shadow: 0 0 0 0, 0 6px 12px rgba($base-color,0.2);
  // box-shadow: 0px 0px 15px 0px rgba(255,255,255,1);
  border-radius: 3px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0px;
  overflow: hidden;
  color: lighten($base-color, 30);
}

.card:hover {
  color: $highlight-color;
}

.card-img {
  display: flex;
  margin: 0px;
  height: 100%;
  align-items: center;
  // background-color: lighten($base-color, 30);
  a:link,a:hover,a:visited,a:active{
    color: lighten($base-color, 30);
  }
}

.card-img i {
  padding: 10px;
  color: lighten($base-color, 30);
}

.card-body {
  height: 100%;
  flex: 0 1 auto;
  display: flex;
  /*flex-direction: column;*/
  margin: 5px;
  padding: 5px;
  align-items: center;
}


/*---- TWO COLUMN LAYOUT -------------------------------------------------------*/
 .grid {
  display: block;
  align-items: center;
  flex-wrap: wrap;
 }

.col-1-2 {
  align-content: space-between;
  flex: 1;
  padding-left: 0px;
  padding-right: 0px;

}
.col-1-2:last-child {
  margin-left: 0px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-grow: 2;
}

@media #{$toosmall} {
  .col-1-2:first-child {
    display: none;
  }
}

@media #{$toosmall} {
  .col-1-2:last-child {
    padding-left: 10px;
    padding-right: 10px;
  }
}